/*eslint-disable */
// import AffiliateModel from '../../Model/Affiliate'
import request from '../../Utils/curl'

let Affiliates = {
  /**
   * AffiliateList
   */
  async AffiliateList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "affiliate_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at AffiliateList() and Exception:',err.message)
    }
  },

  /**
   * AffiliateView
   */
  async AffiliateView (context, AffiliateId) {
    try {      
      let post_data = new FormData();
      post_data.append('aff_id', AffiliateId);
      return await request.curl(context, "affiliate_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at AffiliateView() and Exception:",err.message)
    }    
  },

  /**
   * AffiliateAdd
   */
  async AffiliateAdd (context, AffiliateObj) {
    try{
      let post_data = new FormData();
    
      for (let key in AffiliateObj) {
        post_data.append(key, AffiliateObj[key]);
      }

      return await request.curl(context, "affiliate_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at AffiliateAdd() and Exception:',err.message)
    }
  },

  /**
   * AffiliateEdit
   */
  async AffiliateEdit (context, AffiliateObj) {
    try{
      let post_data = new FormData();
    
      for (let key in AffiliateObj) {
        post_data.append(key, AffiliateObj[key]);
      }

      return await request.curl(context, "affiliate_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at AffiliateEdit() and Exception:',err.message)
    }
  },

  /**
   * AffiliateDelete
   */
  async AffiliateDelete (context, AffiliateId) {
    try{
      let post_data = new FormData();
      
      post_data.append('aff_id', AffiliateId);

      return await request.curl(context, "affiliate_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at AffiliateDelete() and Exception:',err.message)
    }
  },
  /**
   * List all affiliated organisations
   */
  async AffiliateListAffOrgs (context, eventId=null) {
    try{
      let post_data = new FormData();
      
      if (eventId) {
        post_data.append('event_id', eventId);
      }

      return await request.curl(context, "affiliate_list_affiliated_orgs", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at AffiliateListAffOrgs() and Exception:',err.message)
    }
  },
  /**
   * verifies the authenticity of affiliate codes
   */
  async AffiliateVerify (context, affiliateObj) {
    try{
      let post_data = new FormData();
    
      for (let key in affiliateObj) {
        post_data.append(key, affiliateObj[key]);
      }

      return await request.curl(context, "affiliate_verify_code", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at AffiliateVerify() and Exception:',err.message)
    }
  },
  /**
   * AffiliateGetCode
   */
  async AffiliateGetCode (context, affiliateObj) {
    try{
      let post_data = new FormData();
    
      for (let key in affiliateObj) {
        post_data.append(key, affiliateObj[key]);
      }

      return await request.curl(context, "affiliate_get_code", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at AffiliateGetCode() and Exception:',err.message)
    }
  },
}


export {
  Affiliates
}
