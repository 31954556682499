/*eslint-disable */
<template>
  <b-container fluid class="p-0 program_detail_model">
    <b-modal v-if="showModal || editMode" v-model="showModal" size="lg"  :hideHeaderClose='true' :noCloseOnBackdrop="true" no-close-on-backdrop no-close-on-esc>
      <template #modal-header="">
        <h4>
          Program and Test Detail
        </h4>
        <b-button size="sm" class="closeEduModal"  @click="CloseProgramModal()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>
      <b-row class="programTextContainer">
        <b-col md="12" class="admissionForm">
          <b-card p-0>
            <template v-slot:headerTitle>
              <h4 class="card-title">
                {{ cvCardTitle }}
              </h4>
            </template>
            <template>
              <div class="row w-100 m-0">
                <ValidationObserver ref="programForm" class="row w-100 m-0">
                  <div class="form-group col-12">
                    <label for="ExamSelect">Select Exam</label>
                    <ValidationProvider name="Select Exam"  v-slot="{ errors }" rules='required'>
                      <select v-model="vmProgramTest['exam_name']" class="form-control mb-0" id="Select Exam">
                        <option value="SAT">
                          SAT
                        </option>
                        <option value="ACT">
                          ACT
                        </option>
                        <option value="IELTS">
                          IELTS
                        </option>
                        <option value="TOEFL">
                          TOEFL
                        </option>
                      </select>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <template v-if="vmProgramTest.exam_name === 'SAT'">
                    <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12">
                      <label for="SAT Exam Date">SAT Exam Date</label>
                      <ValidationProvider name="SAT Exam Date"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['exam_date']" type="date" class="form-control mb-0" id="SAT Exam Date">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12">
                      <label for="Critical Reading">Critical Reading</label>
                      <ValidationProvider name="Critical Reading"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['critical_reading']" type="text" class="form-control mb-0" id="Critical Reading">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12">
                      <label for="Math">Math</label>
                      <ValidationProvider name="Math"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['math_score']" type="text" class="form-control mb-0" id="Math">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12">
                      <label for="Writing">Writing</label>
                      <ValidationProvider name="Writing"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['writing_score']" type="text" class="form-control mb-0" id="Writing">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-8 col-sm-9 col-lg-5 col-xs-12">
                      <label for="evidence_based_reading_writing">Evidence-Based Reading and Writing</label>
                      <ValidationProvider name="Evidence-Based Reading and Writing"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['evidence_based_reading_writing_score']" type="text" class="form-control mb-0" id="evidence_based_reading_writing">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </template>

                  <template v-if="vmProgramTest.exam_name === 'ACT'">
                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="ACT Exam Date">ACT Exam Date</label>
                      <ValidationProvider name="ACT Exam Date"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['exam_date']" type="date" class="form-control mb-0" id="ACT Exam Date">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Reading">Reading</label>
                      <ValidationProvider name="Reading"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['reading_score']" type="text" class="form-control mb-0" id="Reading">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Math">Math</label>
                      <ValidationProvider name="Math"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['math_score']" type="text" class="form-control mb-0" id="Math">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Science">Science</label>
                      <ValidationProvider name="Science"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['science_score']" type="text" class="form-control mb-0" id="Science">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="English">English</label>
                      <ValidationProvider name="English"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['english_score']" type="text" class="form-control mb-0" id="English">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Composite Score">Composite Score</label>
                      <ValidationProvider name="Composite Score"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['composite_score']" type="text" class="form-control mb-0" id="Composite Score">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </template>

                  <template v-if="vmProgramTest.exam_name === 'IELTS'">
                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="IELTS Exam Date">IELTS Exam Date</label>
                      <ValidationProvider name="IELTS Exam Date"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['exam_date']" type="date" class="form-control mb-0" id="IELTS Exam Date">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Reading">Reading</label>
                      <ValidationProvider name="Reading"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['reading_score']" type="text" class="form-control mb-0" id="Reading">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Writing">Writing</label>
                      <ValidationProvider name="Writing"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['writing_score']" type="text" class="form-control mb-0" id="Writing">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Listening">Listening</label>
                      <ValidationProvider name="Listening"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['listening_score']" type="text" class="form-control mb-0" id="Listening">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Speaking">Speaking</label>
                      <ValidationProvider name="speaking"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['Speaking_score']" type="text" class="form-control mb-0" id="Speaking">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Overall Score">Overall Score</label>
                      <ValidationProvider name="Overall Score"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['overscore_score']" type="text" class="form-control mb-0" id="Overall Score">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </template>

                  <template v-if="vmProgramTest.exam_name === 'TOEFL'">
                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="TOEFL Exam Date">TOEFL Exam Date</label>
                      <ValidationProvider name="TOEFL Exam Date"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['exam_date']" type="date" class="form-control mb-0" id="TOEFL Exam Date">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Reading">Reading</label>
                      <ValidationProvider name="Reading"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['reading_score']" type="text" class="form-control mb-0" id="Reading">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Writing">Writing</label>
                      <ValidationProvider name="Writing"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['writing_score']" type="text" class="form-control mb-0" id="Writing">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Listening">Listening</label>
                      <ValidationProvider name="Listening"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['listening_score']" type="text" class="form-control mb-0" id="Listening">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Speaking">Speaking</label>
                      <ValidationProvider name="speaking"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['Speaking_score']" type="text" class="form-control mb-0" id="Speaking">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group col-md-4 col-sm-6 col-lg-4">
                      <label for="Overall Score">Overall Score</label>
                      <ValidationProvider name="Overall Score"  v-slot="{ errors }" rules='required'>
                        <input v-model="vmProgramTest['overscore_score']" type="text" class="form-control mb-0" id="Overall Score">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </template>
                </ValidationObserver>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="CloseProgramModal()">
          Cancel
        </b-button>
        <b-button size="sm" variant="primary" @click="addAdmission()">
          Save
        </b-button>
      </template>
    </b-modal>

    <template v-if="editMode || !showModal">
      <div class="educationCard viewProgramCard">
        <div class="row ml-2 w-100 programDetail pr-3">
          <h4 class="instituteTitle_block">
            <span>{{vmProgramTest.exam_name}}</span>
            <span class="educationAddIcon" @click="editProgramOpen()">
              <i class="fa-solid ri-pencil-line"></i>
            </span>
          </h4>
          <p class="mb-0">
            <span>{{vmProgramTest['exam_date'] | dateFormatDDMMYYYY}}</span>
          </p>

          <template v-if="vmProgramTest.exam_name === 'SAT'">
            <table class="examTable w-100 ml-0 pl-0 table_scroll">
              <tr class="examTableHeaderRow" >
                <th>
                  <h6>
                    Critical Reading
                  </h6>
                </th>
                <th>
                  <h6>
                    Math
                  </h6>
                </th>
                <th>
                  <h6>
                    Writing
                  </h6>
                </th>
                <th>
                  <h6>
                    Evidence-Based Reading and Writing
                  </h6>
                </th>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>{{vmProgramTest['critical_reading']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['math_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['writing_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['evidence_based_reading_writing_score']}}</span>
                  </p>
                </td>
              </tr>
            </table>
          </template>

          <template v-if="vmProgramTest.exam_name === 'ACT'">
            <table class="examTable w-100 ml-0 pl-0 table_scroll">
              <tr class="examTableHeaderRow">
                <th>
                  <h6>
                    Reading
                  </h6>
                </th>
                <th>
                  <h6>
                    Math
                  </h6>
                </th>
                <th>
                  <h6>
                    Science
                  </h6>
                </th>
                <th>
                  <h6>
                    English
                  </h6>
                </th>
                <th>
                  <h6>
                    Composite Score
                  </h6>
                </th>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>{{vmProgramTest['reading_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['math_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['science_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['english_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest["composite_score"]}}</span>
                  </p>
                </td>
              </tr>
            </table>
          </template>

          <template v-if="vmProgramTest.exam_name === 'IELTS'">
            <table class="examTable w-100 ml-0 pl-0 table_scroll">
              <tr class="examTableHeaderRow">
                <th>
                  <h6>
                    Reading
                  </h6>
                </th>
                <th>
                  <h6>
                    Writing
                  </h6>
                </th>
                <th>
                  <h6>
                    Listening
                  </h6>
                </th>
                <th>
                  <h6>
                    Speaking
                  </h6>
                </th>
                <th>
                  <h6>
                    Overall Score
                  </h6>
                </th>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>{{vmProgramTest['reading_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['writing_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['listening_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['Speaking_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['overscore_score']}}</span>
                  </p>
                </td>
              </tr>
            </table>
          </template>

          <template v-if="vmProgramTest.exam_name === 'TOEFL'">
            <table class="examTable w-100 ml-0 pl-0 table_scroll">
              <tr class="examTableHeaderRow">
                <th>
                  <h6>
                    Reading
                  </h6>
                </th>
                <th>
                  <h6>
                    Writing
                  </h6>
                </th>
                <th>
                  <h6>
                    Listening
                  </h6>
                </th>
                <th>
                  <h6>
                    Speaking
                  </h6>
                </th>
                <th>
                  <h6>
                    Overall Score
                  </h6>
                </th>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>{{vmProgramTest['reading_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['writing_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['listening_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['Speaking_score']}}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{{vmProgramTest['overscore_score']}}</span>
                  </p>
                </td>
              </tr>
            </table>
          </template>
        </div>
      </div>
    </template>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style lang="scss">
input[type="text"], input[type="date"]  {
  width: 100%;
}
.examTable {
  margin-left: 7px;
  .examTableHeaderRow {
    border-bottom: 1px solid var(--iq-border-light);
    height: 40px;
  }
}
.programTextContainer {
  .admissionForm {
    select {
        display: block;
        width: 100%;
        height: 33px;
        padding: 5px 25px 5px 5px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.42857143;
        color: #555;
        background: 6px #efefef;
        border: 1px solid #ddd;
        border-radius: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }
    .sub-ttl {
      font-size: 15px;
      margin-top: 0px;
      color: #0B446F;
      font-weight: 700;
      margin-bottom: 15px;
      line-height: 24px;
      width: 100%;
      span {
        text-transform: none;
        font-weight: 400;
        font-size: 13px;
        margin-left: 10px;
        color: #666;
      }
    }
    .radioCol {
      label {
        margin-left: 10px;
      }
    }
  }
  .resultAwait {
    input {
      margin-top: 31px;
      margin-right: 4px;
    }
  }
}
.programDetail {
  margin: 0;
  h4 {
    width: 100%;
    .educationAddIcon {
      float: right;
      color: var(--iq-primary);;
    }
  }
  input {
    border: none;
  }
  .cardHeader {
    padding-left: 13px;
    width: 100%;
  }
  hr {
    width: 100%;
  }
}
.viewProgramCard {
  box-shadow: none !important;
  .iq-card-body {
    padding-left: 0 !important;
    padding-top: 2px !important;
  }
}
.card-body{
  padding:0px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { User } from "../../FackApi/api/user.js"
import config from "../../Utils/config.js"

export default {
  name: "ProgramTest",
  props: {
    propProgramDetail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propUserView: {
      default: true,
      type: Boolean
    },
    propAdmId: {
      default: null
    }
  },
  data () {
    return {
      vmProgramTest: {
      },
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Admission Response",
      showModal: false,
      cvClassOptions: config.ClassList,
      editMode: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    if (this.propProgramDetail) {
      this.vmProgramTest = { ...this.propProgramDetail }
    }
    if (this.propOpenedInModal) {
      this.showModal = true
    }
  },
  methods: {
    /**
     * editProgramOpen
     */
    editProgramOpen () {
      this.showModal = true
      this.editMode = true
    },
    /**
     * addAdmission
     */
    async addAdmission () {
      let params = {
        user_program_test: JSON.stringify(this.vmProgramTest)
      }

      let userEditResp = await User.useProfileAdd(this, params)
      if (userEditResp.resp_status) {
        this.$emit("emitCloseProgramModal", userEditResp)
        this.editMode = false
        this.showModal = false
      }
    },
    /**
     * CloseProgramModal
     */
    CloseProgramModal () {
      this.editMode = false
      this.showModal = false
      this.$emit("emitCloseProgramModal", false)
    }
  }
}
</script>
