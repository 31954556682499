import { render, staticRenderFns } from "./Paygw.vue?vue&type=template&id=1336f922&scoped=true"
import script from "./Paygw.vue?vue&type=script&lang=js"
export * from "./Paygw.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./Paygw.vue?vue&type=style&index=1&id=1336f922&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1336f922",
  null
  
)

export default component.exports