<template>
  <b-modal v-model="showModal" size="md" title="Please Add your mobile number" :hideHeaderClose='true'  :noCloseOnBackdrop="true">
    <template>
      <ValidationObserver ref="otpForm">
        <form :key="updateKey">
          <div class="form-group row w-100">
            <div class="col-md-6 mb-3">
              <label for="validation_country">Country</label>
              <select v-model="vmUserFormData.user_country" :disabled="showModelAskOtpBox" class="form-control">
                <template>
                  <option v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                    {{country.country_name}} (+ {{country.country_phone_code}})
                  </option>
                </template>
              </select>
            </div>

            <div class="col-md-6 mb-3">
              <ValidationProvider name="Mobile Number" rules="required" v-slot="{ errors }">
                <label for="otpno">Mobile number</label>
                <input type="number" class="form-control" id="user_mobile" v-model="vmUserFormData.user_mobile" name="Mobile Number"/>
                <span class="text-danger">{{ errors[0] }}</span>
                <span class="clearSpanIcon" v-if="showModelAskOtpBox" @click="clear()"><i class="fa-solid fa-circle-xmark"></i></span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group" v-if="showModelAskOtpBox">
            <label for="otpno">OTP</label>
            <ValidationProvider name="Otp" rules="required|min:6|max:6" v-slot="{ errors }">
              <input type="text" class="form-control" id="otp" v-model="vmOtpCode" name="OTP"/>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </form>
      </ValidationObserver>
      <span class="ResendSpan" v-if="showModelAskOtpBox" @click="resendOtp()">Resend OTP</span>
    </template>
    <template #modal-footer="">
      <b-button size="sm"  v-if="propShowCancelBtn" @click="close()">
        Cancel
      </b-button>
      <b-button v-if="showModelAskOtpBox" size="sm" variant="primary" @click="verifyOTP()" :disabled="!vmOtpCode || vmOtpCode && vmOtpCode.length!=6">
        Verify
      </b-button>
      <b-button v-else-if="!showModelAskOtpBox" size="sm" variant="primary" @click="sendOtp()">
        Send OTP
      </b-button>
    </template>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-modal>
</template>
<style lang="scss">
.ResendSpan {
  color: var(--iq-primary);
  cursor: pointer;
  float: right;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.clearSpanIcon {
  position: absolute;
  right: 12px;
  top: 37px;
  font-size: 20px;
  cursor: pointer;
}
</style>
<script>
import { Countries } from "../../FackApi/api/country.js"
import { User } from "../../FackApi/api/user.js"
import { ValidationProvider } from "vee-validate"
import ApiResponse from "../../Utils/apiResponse.js"

export default {
  name: "Otp",
  props: {
    propOpenModal: {
      default: false
    },
    propShowCancelBtn: {
      default: true
    }
  },
  components: {
    ValidationProvider
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  data () {
    return {
      cvOTP: "Enter the otp",
      cvOtpDesc: "OTP has been send to your mobile no.",
      cvOtpLAbel: "OTP",
      vmOtpCode: "",
      vmUserFormData: {},
      showModal: false,
      showModelAskOtpBox: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "OTP",
      cvCountryOptions: "",
      cvUserCountryLabel: "Country",
      cvSelectLabel: "Select ",
      value: null,
      showMobileOtpModal: false,
      updateKey: 1
    }
  },
  async mounted () {
    this.vmUserFormData.user_mobile = this.userData.user_mobile ? this.userData.user_mobile : ""

    if (this.vmUserFormData.user_mobile.length > 10 && (this.vmUserFormData.user_mobile.slice(0, 2) === "91")) {
      this.vmUserFormData.user_mobile = this.vmUserFormData.user_mobile.substring(2)
    }

    if (this.propOpenModal) {
      this.showModal = true
    }
    this.countryDistinctList()
  },
  methods: {
    /**
     * close
     */
    close () {
      this.showModelAskOtpBox = false
      this.$emit("emitCloseOtpModal", true)
    },
    /**
     * clear
     */
    clear () {
      this.vmUserFormData.user_mobile = null
      this.vmOtpCode = null
      this.showModelAskOtpBox = false
    },
    /**
     * resendOtp
     */
    async resendOtp () {
      let data = {
        user_id: this.userData.user_id,
        user_mobile: `+${this.vmUserFormData.user_country.country_phone_code}` + this.vmUserFormData.user_mobile
      }
      let profileEditResp = await User.ReSendOtp(this, data)
      await ApiResponse.responseMessageDisplay(this, profileEditResp)
    },
    /**
     * SendOtp
    */
    async sendOtp () {
      try {
        if (this.vmUserFormData.user_mobile && this.vmUserFormData.user_mobile.toString().length > 6) {
          let data = {
            user_id: this.userData.user_id,
            user_mobile: `+${this.vmUserFormData.user_country.country_phone_code}` + this.vmUserFormData.user_mobile
          }

          if (this.userData.user_mobile && !this.userData.user_mobile.includes("+")) {
            // If mobile number is without country code then add selected country code
            this.userData.user_mobile = `+${this.vmUserFormData.user_country.country_phone_code}` + this.userData.user_mobile
          }

          if (data.user_mobile === this.userData.user_mobile && this.userData.user_mobile_verified && Number(this.userData.user_mobile_verified) === 1) {
            this.toastMsg = "Mobile number is already verified."
            this.showToast = true
            this.toastVariant = "danger"
            this.$store.commit("User/setUserProfile", this.userData)
            this.$emit("emitCloseOtpModal", true)
            return
          }

          let profileEditResp = await User.SendOtp(this, data)
          if (profileEditResp.resp_status) {
            this.showModelAskOtpBox = true
          }
          await ApiResponse.responseMessageDisplay(this, profileEditResp)
        }
        else {
          this.toastMsg = "Mobile number is not valid."
          this.showToast = true
          this.toastVariant = "danger"
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    /**
     * otp verification
     */
    async verifyOTP () {
      try {
        let data = {
          user_id: this.userData.user_id,
          otp_code: this.vmOtpCode,
          user_country: this.vmUserFormData.user_country.id,
          user_mobile: this.vmUserFormData.user_mobile
        }

        let otpResp = await User.Verify_OTP(this, data)
        ApiResponse.responseMessageDisplay(this, otpResp)
        /* Verified if there is no err**/
        if (!otpResp.resp_status) {
          return false
        }

        this.userData.user_mobile = this.vmUserFormData.user_mobile
        this.userData.user_country = data.user_country
        this.userData.country_name = this.vmUserFormData.user_country.country_name
        this.userData.country_id = data.user_country

        this.userData.user_mobile_verified = 1
        this.$store.commit("User/setUserProfile", this.userData)
        this.showModelAskOtpBox = false
        this.$emit("emitCloseOtpModal", true)
      }
      catch (err) {
        console.error("Exception occurred at verifyOTP() and Exception:", err.message)
      }
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }

        this.cvCountryOptions = countryDistinctListResp.resp_data.data

        const findCountryIndex = this.cvCountryOptions.findIndex(country => {
          /*eslint-disable */
          if (country.id == this.userData.country_id || country.id == this.userData.user_country) {
            return true
          }
          /* eslint-enable */
        })

        if (findCountryIndex >= 0) {
          this.vmUserFormData.user_country = this.cvCountryOptions[findCountryIndex]

          const countryCode = "+" + this.vmUserFormData.user_country.country_phone_code
          if (this.userData.user_mobile) {
            // to remove country code from mobile number
            this.updateKey += 1
            this.vmUserFormData.user_mobile = this.userData.user_mobile.replace(countryCode, "")
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    }
  }
}
</script>
