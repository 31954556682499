/*eslint-disable */
<template>
  <b-container fluid v-if="selectedPlan">
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">
          <i class="fa-solid fa-credit-card" aria-hidden="true" style="font-size: 21px; margin-right: 3px; color: var(--iq-primary);"></i>
          {{cvCardTitle}}
        </h4>
        <h4><span class="ml-2" v-if="propModuleName">({{ propModuleName.toUpperCase() }})</span></h4>
      </template>
    </iq-card>

    <b-row>
      <b-col lg="8">
        <iq-card >
          <template v-slot:body>
            <div class="row" :key="selectedBill ? selectedBill.bill_id : ''" v-if="!selectedBill && !addshowBillAdd || selectedBill && !vmShowEditBill">
              <b-col  v-for="bill of adminBillList" :key="bill.bill_id" class="selectBill bill_card_col col-12" @click="select_bill(bill)">
                <!-- bg-primary text-white -->
                <iq-card
                  :class="selectedBill && selectedBill.bill_id === bill.bill_id ? '' : ''"
                  class="bill_card p-3">
                  <template v-slot:body>
                    <i class="fa fa-check checkIcon" v-if="selectedBill && selectedBill.bill_id === bill.bill_id"></i>
                    <span class="font-size-16 text-uppercase">{{ bill.bill_name }}</span>
                    <i class="fa-solid fa-pen editIcon" @click="editBill(bill)"></i>
                    <br>
                    <span class="font-size-14">{{bill.bill_company_name}} </span>
                    <br>

                    <span class="font-size-14">{{bill.bill_city}} </span>,
                    <span class="font-size-14">{{bill.bill_zip_code}} </span>
                    <br>
                    <ul class="list-unstyled line-height-4 mb-0" >
                      <li>
                        {{bill.bill_address}}
                      </li>
                    </ul>
                  </template>
                </iq-card>
              </b-col>
              <b-col col-12 @click="showBillAdd()">
                <button class="btn button-primary1"><i class="ri-add-line side-left-icon"></i> Add New
                </button>
              </b-col>
            </div>
            <form class="p-3" action="#" v-if="selectedBill && vmShowEditBill || addshowBillAdd">
              <div class="form-row">
                <div class="col-md-6 mb-3 lg-4">
                  <label for="validationbill_name">Name <span class="primary-color">*</span></label>
                  <input
                    v-model="vmBillingDataFormData.bill_name"
                    type="text"
                    class="form-control"
                    required/>
                </div>

                <div class="col-md-6 mb-3 lg-4">
                  <label for="validationbill_phone">Phone No. <span class="primary-color">*</span></label>
                  <input
                    v-model="vmBillingDataFormData.bill_phone"
                    type="tel"
                    class="form-control"
                    required/>
                </div>

                <div class="col-md-12 mb-3 lg-12">
                  <label for="validationbill_address">Address  <span class="primary-color">*</span></label>
                  <input
                    v-model="vmBillingDataFormData.bill_address"
                    type="text"
                    class="form-control"
                    required/>
                </div>

                <div class="col-md-6 mb-3 lg-4">
                  <label for="validationbill_city">City  <span class="primary-color">*</span></label>
                  <input
                    v-model="vmBillingDataFormData.bill_city"
                    type="text"
                    class="form-control"
                    required/>
                </div>

                <div class="col-md-6 mb-3">
                  <label for="validationbill_zip_code">Zip Code  <span class="primary-color">*</span></label>
                  <input
                    v-model="vmBillingDataFormData.bill_zip_code"
                    type="text"
                    class="form-control"
                    required/>
                </div>

                <div class="col-md-6 mb-3">
                  <label for="validationbill_state">State</label>
                  <select name="bill_state" id="validationbill_state" class="form-control" v-model="vmBillingDataFormData.bill_state" required>
                    <option :value="GSTStateCode[vmBillingDataFormData.bill_state_code]" selected>{{GSTStateCode[vmBillingDataFormData.bill_state_code]}}</option>
                    <option :value="index" v-for="(key, index) in GSTStateCode" :key="index">{{key}}</option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="validationbill_country">Country</label>
                  <input
                    v-model="vmBillingDataFormData.bill_country"
                    type="text"
                    class="form-control"
                    required/>
                </div>

                <!--
                <div class="col-md-6 mb-3">
                  <label for="validationbill_company_name"> Company Name</label>
                  <input
                    v-model="vmBillingDataFormData.bill_company_name"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="validationbill_gstin"> GSTIN</label>
                  <input
                    v-model="vmBillingDataFormData.bill_gstin"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              -->
              </div>

              <div class="form-group bill_buttons">
                <button
                  v-if="cvLoadingStatus"
                  type="button"
                  class="btn btn-primary"
                  :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary pull-right add_billing"
                  @click="submitBilling()">
                  {{selectedBill && selectedBill.bill_id ? 'Save' : 'Add'}} Billing Details
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="cancelAddEditBill()">
                  Cancel
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>

      <!-- PAYGW MODULEs -->
      <b-col lg="4" v-if="selectedPlan && selectedPlan.subs_plan_id" class="subPlanRow">
        <iq-card body-class="text-center" :class="selectedPlan.active ? 'bg-primary text-white' : ''">
          <template v-slot:body>
            <span class="font-size-16 text-uppercase" v-if="selectedPlan.plan !== ''">{{ selectedPlan.subs_plan_name }}</span>
            <h2 class="display-3 font-weight-bolder" :class="selectedPlan.active ? 'text-white' : ''">
              {{selectedPlan.currency_symbol}}
              <span v-if="myPlan && myPlan.subs_id && selectedPlan.subs_plan_price_gideplus">
                <del style="color: red;">
                  <small style="font-size: 17px; text-decoration: line-through">
                    {{selectedPlan.subs_plan_price}}
                  </small>
                </del>
                {{selectedPlan.subs_plan_price_gideplus}}
              </span>
              <span v-else>
                {{ selectedPlan.subs_plan_price }}
              </span>

              <small class="font-size-14 " :class="selectedPlan.active ? 'text-white' : 'text-muted'">
                <template v-if="selectedPlan.subs_plan_interval && selectedPlan.subs_plan_interval !== '0'"> / {{ getSubPlanIntervalText(selectedPlan.subs_plan_interval) }}
                </template>
              </small>
            </h2>

            <!-- Show in case of Multiple Organisation Allocated to User -->
            <div v-if="!selectedBill || selectedBill && !vmShowEditBill">
              <div class="custom-control custom-checkbox d-inline-block mb-0 pl-0 col-12">
                <div v-if="cvBuySubForOptions.length > 0 && showBuySubsFor" class="multiselectParentDiv mt-3 col-12 w-100">
                  <label>{{cvSubsForLabel}} <strong style="color:red;">*</strong></label>
                  <multiselect
                    v-model="vmModuleObjId"
                    :options="cvBuySubForOptions"
                    track-by="id"
                    label="name"
                    :placeholder="cvSelectLabel + cvSubsForLabel"
                    :tag-placeholder="cvSelectLabel + cvSubsForLabel">
                  </multiselect>
                </div>
                <input type="checkbox" class="custom-control-input" id="customCheck1"  v-model="tnc">
                <label class="custom-control-label text-white mt-2 mr-4 dontShow" for="customCheck1" style="margin-left: -10px;">I accept <span style="fonto"><a href="/content/Tnc" class="text-white" target="_blank"><small> Terms and Conditions </small></a> </span></label>
              </div><!-- Show in case of Organisation -->

              <!-- Payment via affiliate code -->
              <!-- Dont Show Discoutn Code in case of Payments on Events - ContactUsV1 & to other user roles except Student/parent-->
              <div v-if="!moduleScreenName.includes('ContactUs') && userData.user_role=='USERROLE11114'">
                <span>Please enter the discount code <small><i>(Provided by your GIDE Councellor)</i></small></span>
                <b-row>
                  <b-col md="8"><input type="text" v-model="affiliateCode" class="form-control" /></b-col>
                  <b-col md="4"><button class="btn button-primary1" @click="verifyAffCode" style="display: inline;" :disabled="disable_pay_btn() || disablePaymentBtn">Verify</button></b-col>
                </b-row><br>OR
              </div><!-- Payment via affiliate code -->

              <!-- BANK ACCOUNT -->
              <template v-if="(userRole === 'USERROLE11115' || userRole === 'USERROLE11116' || userRole === 'USERROLE11117') && selectedPlan && selectedPlan.subs_plan_type === 'Gide+'">
                <section class="text-left ml-3 mt-3">
                  Bank Name: HDFC Bank <br>
                  Account Number: 5486789153478945 <br>
                  Branch: Gurgaon <br>
                  IFSC Code: 4874235415687 <br>
                  SWIFT number: HDFCINBB <br>
                  <!-- <span style="font-size: 12px;">*Press on submit after payment</span> -->
                </section>

                <b-button :variant="selectedPlan.active? 'light lightBtn' : 'primary'" :class="(!selectedBill || !selectedPlan) ?'text-black mt-4' : 'mt-4'" @click="submitUnivPayment()">
                  Submit
                </b-button>
              </template><!-- BANK ACCOUNT -->

              <template v-else>
                <!-- STRIPE PGW -->
                <div v-if="stripe_elements_options.clientSecret" :key="stripe_elements_options.clientSecret">
                  <stripe-element-payment
                    ref="paymentRef"
                    :pk="stripe_client_key"
                    :elements-options="stripe_elements_options"
                    :confirm-params="stripe_redirect_params"/>

                  <button :variant="selectedPlan.active? 'light' : 'primary'" class="btn mt-5 btn-light" :class="(!selectedBill || !selectedPlan) ?'text-black':''" :disabled="disablePaymentBtn" @click="stripe_confirm_payment">
                    Pay Now
                  </button>
                </div><!-- STRIPE PGW -->

                <!-- RAZORPAY PGW -->
                <span v-else-if="!(DEVICE_OS == 'ios' && IS_APP && propModuleName != 'counselling')">
                  <b-button :variant="selectedPlan.active? 'light lightBtn' : 'primary'" class="mt-4"
                    :class="(!selectedBill || !selectedPlan) ?'text-black':''"
                    @click="select_payment_gateway()"
                    :disabled="disable_pay_btn() || disablePaymentBtn">
                    Pay Now
                  </b-button><!-- RAZORPAY PGW -->
                  <br><span><i><small>Complete billing details before Payment</small></i></span>
                </span>

                <!-- IOS MOBILE APP -->
                <div v-else class="mt-4">
                  <!-- <div>We apologise, you cannot upgrade to Gide Plus in the IOS app at the moment. We are working hard to bring it to you.</div> -->

                  <!-- APPLE IAP -->
                  <b-button :variant="selectedPlan.active? 'light lightBtn' : 'primary'" class="mt-4"
                    :class="(!selectedBill || !selectedPlan) ?'text-black':''"
                    @click="select_payment_gateway('APPLEPAY')"
                    :disabled="disable_pay_btn() || disablePaymentBtn">
                    Pay Now
                  </b-button><!-- APPLE IAP -->

                  <!-- APPLE IAP ONLY FOR TESTING -->
                  <!-- <div v-if="!VUE_APP_ENV.includes('prod')" class="mt-4">
                    <b-button :variant="selectedPlan.active? 'light lightBtn' : 'primary'" class="mt-4"
                      :class="(!selectedBill || !selectedPlan) ?'text-black':''"
                      @click="select_payment_gateway('APPLEPAY', 'ID1111111111')"
                      :disabled="disable_pay_btn() || disablePaymentBtn">
                      Test Pay Now<br>(PROD-ID: ID1111111111) @ 10INR
                    </b-button><br>
                    <b-button :variant="selectedPlan.active? 'light lightBtn' : 'primary'" class="mt-4"
                      :class="(!selectedBill || !selectedPlan) ?'text-black':''"
                      @click="select_payment_gateway('APPLEPAY', 'ID2222222222')"
                      :disabled="disable_pay_btn() || disablePaymentBtn">
                      Test Pay Now<br>(PROD-ID: ID2222222222) @ 0INR
                    </b-button>
                  </div> -->
                  <!-- APPLE IAP ONLY FOR TESTING -->

                  <div v-if="payment_inprogress_loader" class="text-center mt-3">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                  </div>
                </div>
                <!-- IOS MOBILE -->
              </template>
            </div>

            <div class="col-md-12 moduleslists mt-4">
              <ul>
                <li v-for="(subsModule, indexM) in utility.splitTextBy(selectedPlan.subs_plan_desc, '.')" :key="indexM+'B'">
                  <small>{{ subsModule }}</small>
                </li>
              </ul>
            </div>
          </template>
        </iq-card>
      </b-col><!-- PAYGW MODULEs -->
    </b-row>

    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { PayTransactionss } from "../../../FackApi/api/PayTransactions"
import ApiResponse from "../../../Utils/apiResponse.js"
import { UserBillDetails } from "../../../FackApi/api/UserBillDetails"
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import { StripeElementPayment } from "@vue-stripe/vue-stripe"
import { socialvue } from "../../../config/pluginInit"
import Multiselect from "vue-multiselect"
import config from "../../../Utils/config.js"
import Utility from "../../../Utils/utility"
import { analyticsTracker } from "../../../FackApi/api/analytics/analytics_tracker"
import { Affiliates } from "../../../FackApi/api/Affiliate"
import GSTStateCode from "../../../FackApi/json/GSTStateCode.json"

export default {
  name: "Paygw",
  components: {
    StripeElementPayment, Multiselect
  },
  props: {
    propModuleType: {
      type: String,
      default: null
    },
    propModuleName: {
      default: null
    },
    propModuleObjId: {
      default: null
    },
    propSrc: {
      default: null
    },
    propSubModuleName: {
      default: null
    },
    propSrcId: {
      default: null
    },
    propRedirect: {
      type: String,
      default: "home"
    },
    propEventMktSrc: {
      default: "ONLINE"
    },
    propTrackRevenueFor: {
      type: String,
      default: null
    },
    propUserId: {
      type: String,
      default: null
    },
    propUserEmail: {
      type: String,
      default: null
    },
    propUserRole: {
      type: String,
      default: "USERROLE11114"
    }
  },
  data () {
    return {
      cvCardTitle: "Enter Billing Details",
      IS_APP: window.__IS_APP__,
      IS__MOBILE_DEVICE: window.__IS__MOBILE_DEVICE__,
      DEVICE_OS: window.__DEVICE_OS__,
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      cvCardSubHeader: "Add Billing Details For Invoice",
      cvLoadingStatus: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Billing Details",
      vmBillingDataFormData: Object.assign({}, this.initFormData()),
      selectedBill: null,
      adminBillList: [],
      subscriptionPlanList: [],
      selectedPlan: {},
      pay_gw_razorpay_id: config.PaymentGateWays.razorpay,
      pay_gw_stripe_id: config.PaymentGateWays.stripe,
      pay_gw_bank_id: config.PaymentGateWays.bank,
      pay_gw_appleiap_id: config.PaymentGateWays.appleiap,
      stripe_client_key: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      stripe_elements_options: {
        appearance: {
          theme: "stripe",
          variables: {
            colorPrimary: "#50b5ff"
          }
        }, // appearance options,
        clientSecret: null
      },
      stripe_redirect_params: {
        return_url: window.location.href// success url
      },
      addshowBillAdd: false,
      cvSubsForLabel: "Buy Subscription for",
      cvBuySubForOptions: [],
      tnc: true,
      cvSelectLabel: "Select ",
      vmModuleObjId: null,
      vmShowEditBill: false,
      selectedModuleForPlan: null,
      utility: Utility,
      vmBannerImg: Utility.getBgImageForLetter(this, "E"),
      showBuySubsFor: false,
      disablePaymentBtn: false,
      payment_inprogress_loader: false,
      billingObj: null,
      affiliateCode: "",
      GSTStateCode: GSTStateCode,
      userId: null,
      userEmail: null,
      userRole: null,
      moduleScreenName: null
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    },
    myPlan () {
      return this.$store.getters["Paygw/myPlanState"]
    },
    gpathSession () {
      return this.$store.getters["GpathSession/gpathSession"]
    }
  },
  async mounted () {
    socialvue.index()

    this.userId = this.propUserId ? this.propUserId : this.userData.user_id
    this.userRole = this.propUserRole ? this.propUserRole : this.userData.user_role
    this.userEmail = this.propUserEmail ? this.propUserEmail : this.userData.user_email

    console.info("XXXX", this.userId, window.__IS_APP__, window.__DEVICE_OS__, navigator.userAgent.toLowerCase(), this.$route)

    this.moduleScreenName = this.$route.meta && this.$route.meta.name ? this.$route.meta.name : null
    this.moduleType = this.$route.params.moduleType
    this.moduleObjId = this.$route.params.moduleObjId

    if (this.propModuleType && this.propModuleObjId) {
      this.moduleType = this.propModuleType
      this.moduleObjId = this.propModuleObjId
    }

    if (this.moduleType && this.moduleObjId) {
      await this.getPlanDetails(this.userId)
    }
    else {
      this.selectedPlan = await this.$store.getters["Paygw/selectedPlanState"]
      if (!this.selectedPlan || (this.selectedPlan && !Object.keys(this.selectedPlan).length)) {
        this.$router.back()
      }
    }

    if (!this.selectedPlan) {
      this.selectedPlan = {}
    }

    this.selectedPlan.active = false
    await this.UserBillDetailsList(this.userId)
    this.buySubFor(this.userId)
    this.selectedModuleForPlan = await this.$store.getters["Paygw/selectedModuleForPlanState"]

    if (this.$route.query && this.$route.query.payment_intent_client_secret) {
      this.stripe_check_payment_status(this.$route.query.payment_intent_client_secret)
    }

    this.analyticsTrackerAdd("init") // Init
  },
  methods: {
    /**
     * getSubPlanIntervalText
     */
    getSubPlanIntervalText (interval) {
      switch (interval) {
        case "monthly":
          return "Monthly"
        case "annually":
          return "Annual"
        case "single_use_monthly":
          return "One Time Monthly"
        case "single_use_annually":
        default:
          return "One Time"
      }
    },
    /**
     * verifyAffCode
     */
    async verifyAffCode () {
      const affiliateObj = {
        participant_id: this.userId,
        aff_code: this.affiliateCode,
        gps_id: this.gpathSession.gps_id,
        module_name: this.propModuleName
      }
      const verifyAffCodeResp = await Affiliates.AffiliateVerify(this, affiliateObj)

      if (verifyAffCodeResp.resp_status) {
        // emiting the payment captured event if the code is authentic
        this.$router.push(`/pay_gw_status?status=${"Success"}&redirect=${this.propRedirect}`)
        this.$emit("emitPaymentStatus", "Captured", true)
      }
      else {
        this.toastVariant = "danger"
        this.toastTitle = "Discount Code Verification"
        this.toastMsg = "Discount Code Already Used or is Invalid"
        this.showToast = true
      }
    },
    /**
     * cancelAddEditBill
     */
    cancelAddEditBill () {
      this.addshowBillAdd = false
      this.vmShowEditBill = false
      if (this.adminBillList.length) {
        this.select_bill(this.adminBillList[0])
      }
    },
    /**
     * getPlanDetails which are specific to the parent module
     * Eg1: If Calling from GPATHs- GET GPATH Specific Plans by passing gpath
     * Eg2: If calling from EVENT - GET EVENT Specific Plans by passing event id
     */
    async getPlanDetails (userId = null) {
      try {
        let payload = { currency_code: "INR" }
        if (this.userData.country_name && this.userData.country_name.toLowerCase() !== "india") {
          payload.currency_code = "USD"
        }

        const plan = await SubscriptionPlans.subscriptionPlanViewCustom(this, this.moduleType, this.moduleObjId, payload, this.propSubModuleName, this.userId, this.userRole)
        if (plan && plan.resp_status) {
          this.selectedPlan = plan.resp_data.data
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    /**
    * buySubFor
    * buy sub for org or student and educator
    */
    buySubFor () {
      let type = "USERROLE11114"
      if (this.userRole == "USERROLE11112") {
        type = "EDUCATOR"
      }
      else if (this.userRole == "USERROLE11113") {
        type = "PARENT"
      }
      else if (this.userRole == "USERROLE11114") {
        type = "STUDENT"
      }
      else if (this.userRole == "USERROLE11115") {
        type = "UNIV"
      }
      else if (this.userRole == "USERROLE11116") {
        type = "ORG"
      }
      else if (this.userRole == "USERROLE11117") {
        type = "SCH"
      }
      else if (this.userRole == "USERROLE11111") {
        type = this.userRole
      }
      else if (this.userRole == "USERROLE11118") {
        type = this.userRole
      }

      this.cvBuySubForOptions.push({ name: `${this.userData.user_name}`, id: this.userId, "type": type })
      this.vmModuleObjId = this.cvBuySubForOptions[0]
      this.showBuySubsFor = false

      if (this.userRole === "USERROLE11115" || this.userRole === "USERROLE11116" || this.userRole === "USERROLE11117") {
        // Buy subs for only visible to univ users who have admins roles for more then one org.
        // In this case user has to select univ of choice for subs.
        if (this.userData.modules && this.userData.modules.organisations) {
          let orgs = this.userData.modules.organisations

          for (let index in orgs) {
            let org = orgs[index]

            let allowedToPost = this.getUserAllowedToPostOrNot(org.org_id)
            if (allowedToPost) {
              this.cvBuySubForOptions.push({ name: `${org.org_name}`, id: org.org_id, "type": type })
            }
          }
          this.vmModuleObjId = this.cvBuySubForOptions[0]
          this.showBuySubsFor = true
        }
      }

      this.vmModuleObjId = this.cvBuySubForOptions[0]
    },
    /**
    * getUserAllowedToPostOrNot
    * @param moduleObjId
    * @returns {boolean}
    */
    getUserAllowedToPostOrNot (moduleObjId) {
      if (this.userData.roles && this.userData.roles[moduleObjId]) {
        if (this.userData.roles[moduleObjId]["udr_name"].toLowerCase().includes("admin") || this.userData.roles[moduleObjId]["udr_name"].toLowerCase() === "owner") {
          return true
        }
        else {
          this.toastVariant = "danger"
          this.toastMsg = "Hey! looks like you are not the admin of this organisation. Only the organisation admin can upgrade."
          this.showToast = true
        }
      }
      else {
        return false
      }
    },
    /*
    *showBillAdd
    */
    showBillAdd () {
      this.selectedBill = null
      this.addshowBillAdd = true
      this.vmBillingDataFormData = Object.assign({}, this.initFormData())

      this.vmBillingDataFormData.bill_name = this.userData.user_name
      if (this.userData.user_mobile) {
        this.vmBillingDataFormData.bill_phone = this.userData.user_mobile
      }
    },
    /**
     * editBill
     */
    editBill (bill) {
      this.selectedBill = { ...bill }
      this.vmShowEditBill = true
      this.vmBillingDataFormData = Object.assign({}, bill)
    },
    /**
     * submitUnivPayment
     */
    async submitUnivPayment () {
      if (!this.selectedBill) {
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Kindly add billing details before submitting"
        return
      }

      let price = this.selectedPlan.subs_plan_price
      if (this.myPlan && this.myPlan.subs_id && this.selectedPlan.subs_plan_price_gideplus) {
        price = this.selectedPlan.subs_plan_price_gideplus
      }

      this.selectedPlan.module_obj_id = this.vmModuleObjId["id"]
      this.selectedPlan.module_obj_type = this.vmModuleObjId["type"]

      const payload = {
        amount: parseFloat(price) * 100,
        currency: this.selectedPlan.currency_code,
        user_bill_id: this.selectedBill.bill_id,
        subs_plan_id: this.selectedPlan.subs_plan_id,
        sub_plan_module_obj_id: this.selectedPlan.sub_plan_module_obj_id,
        pay_gw_id: this.pay_gw_bank_id,
        module_obj_id: this.selectedPlan.module_obj_id,
        module_obj_type: this.selectedPlan.module_obj_type,
        bill_email: this.selectedBill.email,
        pay_paid_for: JSON.stringify(this.selectedModuleForPlan),
        user_id: this.userId
      }

      let payTransactionsAddResp = await PayTransactionss.createOrder(this, payload)
      ApiResponse.responseMessageDisplay(this, payTransactionsAddResp)
      if (payTransactionsAddResp.resp_status) {
        this.$router.push("/pay_gw_status?type=org")
      }
    },
    /**
     * select_payment_gateway
     * PAYGW = RAZORPAY, APPLEPAY, STRIPE
     * PRODID is used only for testing
     */
    async select_payment_gateway (PAYGW = "RAZORPAY", PRODID = null) {
      if (this.cvBuySubForOptions.length === 1) {
        this.vmModuleObjId = this.cvBuySubForOptions[0]
      }
      else if (this.cvBuySubForOptions.length > 1) {
        if (!this.vmModuleObjId) {
          this.toastVariant = "danger"
          this.toastMsg = "Please select subscription for."
          this.showToast = true
          return false
        }
      }

      if (!this.tnc) {
        this.toastVariant = "danger"
        this.toastMsg = "Please accept the terms and conditions."
        this.showToast = true
        return false
      }
      this.selectedPlan.module_obj_id = this.vmModuleObjId["id"]
      this.selectedPlan.module_obj_type = this.vmModuleObjId["type"]

      /*eslint-disable */
      this.disablePaymentBtn = true
      const i = this.selectedPlan.currency_code === 'INR' ? false : true

      let price = this.selectedPlan.subs_plan_price
      if (this.myPlan && this.myPlan.subs_id && this.selectedPlan.subs_plan_price_gideplus) {
        price = this.selectedPlan.subs_plan_price_gideplus
      }

      //Set as per Razor Pay
      this.billingObj = {        
        amount: parseFloat(price) * 100,
        currency: this.selectedPlan.currency_code,
        user_bill_id: this.selectedBill.bill_id,
        subs_plan_id: this.selectedPlan.subs_plan_id,
        sub_plan_module_obj_id: this.selectedPlan.sub_plan_module_obj_id,
        module_obj_id: this.selectedPlan.module_obj_id,
        module_obj_type: this.selectedPlan.module_obj_type,
        module_name: this.selectedPlan.subs_plan_name,        
        bill_email: this.selectedBill.email,
        pay_paid_for: JSON.stringify(this.selectedModuleForPlan),
        device_id: window.__DEVICE_OS__,
        source: this.propEventMktSrc ? this.propEventMktSrc : this.userData.source,
        user_id: this.userId
      }

      let payTransactionsAddResp = {resp_status:false}
      if (PAYGW == "APPLEPAY") {
        this.payment_inprogress_loader = true
        window.context = this

        this.billingObj.pay_gw_id = this.pay_gw_appleiap_id
        this.billingObj.amount = parseFloat(price)+".00"

        let product_id = PRODID ? PRODID : this.billingObj.subs_plan_id

        console.log(PAYGW, "XXXX-1", this.billingObj, payTransactionsAddResp, window.flutter_inappwebview)

        if(window.flutter_inappwebview){
          window.flutter_inappwebview.callHandler('inapp_purchase', product_id)
          .then(function(response) {
            return response;
          });
        }
        else {
          setTimeout(() => {
            window.location.reload()    
          }, 500)

          context.toastTitle = 'Apple Payment'
          context.toastVariant = 'warning'
          context.toastMsg = 'Re-Initialising Payment System'
          context.showToast = true
          context.payment_inprogress_loader = false          
          return false
        }
      }
      else {
        this.billingObj.pay_gw_id = this.pay_gw_razorpay_id
        this.billingObj.amount = parseFloat(price) * 100
  
        payTransactionsAddResp = await PayTransactionss.createOrder(this, this.billingObj)
        this.razorpay_open_checkout_and_pay(payTransactionsAddResp)
  
        await ApiResponse.responseMessageDisplay(this, payTransactionsAddResp)
        if (!payTransactionsAddResp.resp_status) {
          this.disablePaymentBtn = false
          return
        }
      }

      this.disablePaymentBtn = true

      /*
      if (i) {
        this.stripe_show_stripe_element(payTransactionsAddResp)
      }
      else {
        this.razorpay_open_checkout_and_pay(payTransactionsAddResp)
      } */
    },
    /**
     * stripe_show_stripe_element
     */
    async stripe_show_stripe_element (payTransactionsAddResp) {
      // this will trigger the process
      if (payTransactionsAddResp && payTransactionsAddResp.resp_status) {
        this.stripe_elements_options.clientSecret = payTransactionsAddResp.resp_data.client_secret
        this.stripe_elements_options.pay_intent_id = payTransactionsAddResp.resp_data.id
        await this.stripe_check_payment_status(this.stripe_elements_options.clientSecret)
      }
      this.disablePaymentBtn = false
    },
    /**
     * stripe_check_payment_status
     * Fetches the payment intent status after payment submission
    */
    async stripe_check_payment_status (PaymentIntentClientSecret) {
      const { paymentIntent } = await this.$stripe.retrievePaymentIntent(PaymentIntentClientSecret)

      if (!paymentIntent) { return }

      switch (paymentIntent.status) {
        case 'succeeded':
          this.showMessage('Payment succeeded!')

          let resp = await PayTransactionss.captureOrder(this, { user_id: this.user_id, pay_order_id: paymentIntent.id, pay_gw_id: this.pay_gw_stripe_id })

          this.$router.replace({ query: '' })
          this.$router.push(`/pay_gw_status?status=${resp.resp_data.status}`)
          break
        case 'processing':
          this.showMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          this.showMessage('Please pay the ammount.')
          break
        default:
          this.showMessage('Something went wrong.')
          break
      }
    },
    /**
     *showMessage
     */
    showMessage (msg) {
      this.toastMsg = msg
      this.showToast = true
    },
    /**
     * submitBilling Details
    */
    async stripe_confirm_payment () {
      this.$refs.paymentRef.submit()
    },
    /**
     * select_bill
    */
    select_bill (bill) {
      this.vmBillingDataFormData = Object.assign({}, bill)
      this.selectedBill = bill
      this.selectedBill.email = this.userEmail
    },
    /**
     * UserBillDetailsList
    * */
    async UserBillDetailsList (userId) {
      let UserBillDetailsListResp = await UserBillDetails.UserBillDetailsList(this, userId)
      if (!UserBillDetailsListResp.resp_status) {
        this.showBillAdd()
        return false
      }
      this.adminBillList = UserBillDetailsListResp.resp_data.data
      if(this.adminBillList.length) {
        this.select_bill(this.adminBillList[0])
      }
    },
    /**
    * initFormData
    */
    initFormData () {
      return {
        'bill_name': '',
        'bill_email': '',
        'bill_company_name': '',
        'bill_gstin': '',
        'bill_city': '',
        'bill_zip_code': '',
        'bill_state': '',
        'bill_country': '',
        'bill_address': '',
        'bill_phone': ''
      }
    },
    /**
     * submitBilling
     */
    async submitBilling () {
      try {
        this.cvLoadingStatus = true
        let UserBillDetailsResp
        // setting bill_state and bill_state_code
        this.vmBillingDataFormData.bill_state_code = this.vmBillingDataFormData.bill_state
        this.vmBillingDataFormData.bill_state = this.GSTStateCode[this.vmBillingDataFormData.bill_state]

        if (this.selectedBill && this.selectedBill.bill_id) {
          this.selectedBill.bill_email = this.userEmail
          this.vmBillingDataFormData.email = this.userEmail
          UserBillDetailsResp = await UserBillDetails.UserBillDetailsEdit(this, { ...this.vmBillingDataFormData })
          if (!UserBillDetailsResp.resp_status) return

          const findIndex = this.adminBillList.findIndex(bill => bill.bill_id === this.vmBillingDataFormData.bill_id)
          if (findIndex >= 0) {
            this.adminBillList[findIndex] = this.vmBillingDataFormData
          }
        }
        else {
          this.vmBillingDataFormData.bill_email = this.userEmail
          UserBillDetailsResp = await UserBillDetails.UserBillDetailsAdd(this, this.vmBillingDataFormData)

          if (UserBillDetailsResp && !UserBillDetailsResp.resp_status) {
            return false
          }

          if (!this.adminBillList) {
            this.adminBillList = []
          }

          this.selectedBill = { ...UserBillDetailsResp.resp_data }
          this.vmBillingDataFormData = Object.assign({}, this.selectedBill)
          this.selectedBill = null
          this.adminBillList.push(UserBillDetailsResp.resp_data)
          this.addshowBillAdd = false
        }

        await ApiResponse.responseMessageDisplay(this, UserBillDetailsResp)
      }
      catch (err) {
        console.error('Exception occurred at submitBilling() and Exception:', err.message)
      }
      finally {
        this.cvLoadingStatus = false

        if(this.adminBillList.length) {
          this.select_bill(this.adminBillList[0])
        }

        if (this.selectedBill && this.vmShowEditBill) {
          this.vmShowEditBill = false
        }
      }
    },
    /**
     * razorpay_open_checkout_and_pay
     */
    async razorpay_open_checkout_and_pay (payTransactionsAddResp) {
      let self = this

      let price = this.selectedPlan.subs_plan_price
      if (this.myPlan && this.myPlan.subs_id && this.selectedPlan.subs_plan_price_gideplus) {
        price = this.selectedPlan.subs_plan_price_gideplus
      }

      // https://razorpay.com/docs/payments/payment-gateway/web-integration/standard/webview/
      var options = {
        // callback_url: 'http://localhost:14433/callback_url',
        // redirect: true,
        'key': process.env.VUE_APP_RAZORPAY_CLIENT_KEY,
        'amount': parseFloat(price) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        'currency': this.selectedPlan.currency_code,
        'name': this.selectedPlan.subs_plan_name,
        'description': this.propModuleName ? this.propModuleName :  this.selectedPlan.subs_plan_interval + '/' + this.selectedPlan.subs_plan_modules,
        'order_id': payTransactionsAddResp.resp_data.id,
        'handler': function (response) {
          self.razorpay_verify_n_capture_payment(response)
        },
        'prefill': {
          'name': this.selectedBill.bill_name,
          'email': this.selectedBill.email,
          'contact': this.selectedBill.bill_phone
        },
        'notes': {
          pay_subs_id: this.selectedPlan.subs_plan_id,
          pay_user_bill_id: this.selectedBill.bill_id,
          user_id: this.userId,
          track_revenue_for: this.propTrackRevenueFor ? this.propTrackRevenueFor : null,
          subs_plan_interval: this.selectedPlan.subs_plan_interval,
          modules: this.selectedPlan.subs_plan_modules,
          module_obj_id: this.selectedPlan.module_obj_id,
          module_obj_type: this.selectedPlan.module_obj_type,
          subs_module_id: this.selectedPlan.sub_plan_module_obj_id, // Event, Plan, Admisison Id
          subs_plan_modules: this.selectedPlan.subs_plan_modules,
          module_name: this.selectedPlan.subs_plan_name, // Event, Plan Name
          device_id: window.__DEVICE_OS__,
          src: this.propSrc,
          src_id: this.propSrcId,
          source: this.propEventMktSrc ? this.propEventMktSrc : this.userData.source
        }
      }

      /*eslint-disable */
      var rzp = new Razorpay(options)
      rzp.open()
      rzp.on('payment.failed', function (response) {
        console.log('PAYMENT FAILED',response);
        self.showMessage("Payment Failed");
        self.disablePaymentBtn = false
      })
    },
    /**
     * razorpay_verify_n_capture_payment
     */
    async razorpay_verify_n_capture_payment(response) {
      let price = this.selectedPlan.subs_plan_price
      if (this.myPlan && this.myPlan.subs_id && this.selectedPlan.subs_plan_price_gideplus) {
        price = this.selectedPlan.subs_plan_price_gideplus
      }

      let params = {
        currency_code: this.selectedPlan.currency_code,
        amount: parseFloat(price) * 100,
        pay_gw_id: this.pay_gw_razorpay_id,
        module_name: this.selectedPlan.subs_plan_name, // Event, Plan Name
        module_obj_id: this.selectedPlan.module_obj_id,
        module_obj_type: this.selectedPlan.module_obj_type,
        subs_plan_id: this.selectedPlan.subs_plan_id,
        source: this.propEventMktSrc ? this.propEventMktSrc : this.userData.source,
        device_id: window.__DEVICE_OS__,
        ...response
      }

      Utility.showLoader = true
      Utility.loadingMsg = "Please wait.."

      let resp = await PayTransactionss.captureOrder(this, params);
      Utility.showLoader = false
      Utility.loadingMsg = ""

      let message = "Payment has been processed. It may take some time to process your request. you will receive the confirmation email click on 'Register Button'. In case it is not activated please write to us at " + process.env.VUE_APP_SUPPORT_EMAIL
      //Check if Payment failed
      if (resp.resp_data.status.includes("Fail")) {
        message = "Payment failed please try again"
      }
      else {
        // Called Analytics Tracker with
        this.analyticsTrackerAdd("complete") // Complete
      }

      if (this.propModuleType && !this.propRedirect) {
        const options = {
          title: `Payment ${resp.resp_data.status}`,
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          okTitle: "Ok",
          cancelTitle: "Close",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        }

        const value = await this.$bvModal.msgBoxConfirm(message, options)
        this.$emit("emitPaymentStatus", resp.resp_data.status)
      }
      else {
        this.$emit("emitPaymentStatus", resp.resp_data.status)

        let redirectPath = this.propRedirect
        if (this.userData.user_role == "USERROLE11116") {
          // User is an organisation user so after payment redirect to dashboard
          redirectPath = "dashboard"
          this.$router.push(`/pay_gw_status?status=${resp.resp_data.status}&redirect=${redirectPath}`)
        }
        else {
          if (redirectPath) {
            if (redirectPath.includes("PAYMENT_DONE")) {
              this.$router.push(redirectPath+"&pay_tid="+response.razorpay_payment_id)
            }
            else {
              this.$router.push(`/pay_gw_status?status=${resp.resp_data.status}&redirect=${redirectPath}`)
            }
          }
          else {
            this.$router.push(`/pay_gw_status?status=${resp.resp_data.status}`)
          }
        }
      }
      
      this.disablePaymentBtn = false
    },
    /**
     * disable_pay_btn
     */
    disable_pay_btn(){
      if (!this.selectedBill || !this.selectedPlan || !this.tnc) {
        return true
      }
      if (this.cvBuySubForOptions.length > 0 && !this.vmModuleObjId) {
        //check for organisation and student
        return true
      }
      return false
    },
    /**
     * Start payment initiate process
     */
    async analyticsTrackerAdd (status_code = "init") {
      let payObj = {}
      let selectedPlan = this.selectedPlan

      if(!(this.selectedPlan && this.selectedPlan.subs_plan_name)){
        return
      }

      if(!this.selectedPlan.sub_plan_module_obj_id || this.selectedPlan.sub_plan_module_obj_id == 1) {
        // This is for Subscription Plan Upgrade
        payObj = {
          user_id: this.userId,
          enable3rdPartyTracking: true,
          module_name: "subscription_pay_" + this.selectedPlan.subs_plan_name.substring(0,60).replace(/([^\w]+|\s+)/g, "").toLowerCase(),
          module_obj_id: selectedPlan.subs_plan_id,
          action: "subs_pay_" + status_code,
          email: this.userEmail,
          source: this.propEventMktSrc ? this.propEventMktSrc : this.userData.source
        }
      }
      else {
        // This is for one time payment
        let module_name = this.propModuleName ? this.propModuleName : this.selectedPlan.sub_plan_module_obj_id
        let module_obj_id = this.propModuleObjId ? this.propModuleObjId : this.selectedPlan.subs_plan_id

        payObj = {
          user_id: this.userId,
          enable3rdPartyTracking: true,
          module_name: "event_pay_" + module_name.substring(0,60).replace(/([^\w]+|\s+)/g, "").toLowerCase(),
          module_obj_id: module_obj_id,
          action: "eve_pay_" + status_code,
          email: this.userEmail,
          source: this.propEventMktSrc ? this.propEventMktSrc : this.userData.source
        }
      }

      window.fbq("trackCustom", (payObj.module_name + "_" + status_code), payObj)
      analyticsTracker.analyticsTrackerAdd(this, payObj)
    },
    /**
     * FlutterInappPurchase_purchaseUpdated_listner
     */
    async FlutterInappPurchase_purchaseUpdated_listner (context = null, flutter_payment_response = null) {
      console.log("XXXXX, FlutterInappPurchase_purchaseUpdated_listner-3:", context.billingObj, flutter_payment_response);

      if(!flutter_payment_response) {
        context.toastTitle = 'Apple Payment'
        context.toastVariant = 'danger'
        context.toastMsg = 'Something went wrong. Re-initialising payment system'
        context.showToast = true
        context.payment_inprogress_loader = false

        setTimeout(() => {
          window.location.reload()    
        }, 500)

        return false
      }

      context.billingObj.productId = flutter_payment_response.productId
      context.billingObj.transactionId = flutter_payment_response.transactionId
      context.billingObj.transactionDate = flutter_payment_response.transactionDate
      context.billingObj.transactionReceipt = flutter_payment_response.transactionReceipt

      context.billingObj.notes = JSON.stringify({
        user_name: context.selectedBill.bill_name,
        user_email: context.selectedBill.email,
        user_contact: context.selectedBill.bill_phone,
        pay_subs_id: context.selectedPlan.subs_plan_id,
        pay_user_bill_id: context.selectedBill.bill_id,
        user_id: this.userId,
        subs_plan_interval: context.selectedPlan.subs_plan_interval,
        modules: context.selectedPlan.subs_plan_modules,
        module_obj_id: context.selectedPlan.module_obj_id,
        module_obj_type: context.selectedPlan.module_obj_type,
        subs_module_id: context.selectedPlan.sub_plan_module_obj_id, // Event, Plan, Admisison Id
        module_name: context.selectedPlan.subs_plan_name, // Event, Plan Name
        device_id: window.__DEVICE_OS__
      })

      let payTransactionsAddResp = await PayTransactionss.createOrder(context, context.billingObj)
      context.payment_inprogress_loader = false

      await ApiResponse.responseMessageDisplay(context, payTransactionsAddResp)
      if (!payTransactionsAddResp.resp_status) {
        context.disablePaymentBtn = false
        context.payment_inprogress_loader = false
        return
      }

      if (context.propModuleType) {
        const options = {
          title: `Payment Successful`,
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          okTitle: "Ok",
          cancelTitle: "Close",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        }

        const value = await context.$bvModal.msgBoxConfirm(message, options)
        context.$emit("emitPaymentStatus", "Payment Successful")
      }
      else {
        context.$router.push(`/pay_gw_status?status=Payment Successful`)
      }         
    }
  }
}


/**
 * FlutterInappPurchase_purchaseUpdated_listner
 * This method is called from within the IOS App after receiving a Success response from 
 */
window.FlutterInappPurchase_purchaseUpdated_listner = async function (flutter_payment_response) {  
  try {
    // console.log("XXXXX, FlutterInappPurchase_purchaseUpdated_listner-1:", typeof flutter_payment_response, flutter_payment_response)
    // console.log("XXXXX, FlutterInappPurchase_purchaseUpdated_listner-2:", typeof flutter_payment_response, flutter_payment_response.productId)
    window.context.FlutterInappPurchase_purchaseUpdated_listner(window.context, flutter_payment_response)
  } 
  catch(e){
    console.error("FlutterInappPurchase_purchaseUpdated_listner: ex:", e, flutter_payment_response)
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.button-primary1{
  background-color: var(--iq-primary);
  display: block;
  color: #fff;
  margin: auto !important;
  width: max-content;
}
.selectBill {
  cursor: pointer;
  .card-link {
    margin-left: -17px;
  }
}
.bill_card_col {
  width: 200px;
  .bill_card {
    text-overflow: ellipsis;
    overflow: hidden;
    .checkIcon {
      position: absolute;
      top: 11px;
      right: 15px;
      color: #16c116;
      font-size: 20px;
    }
  }
}
.AddMoreDetail{
  float: right;
  text-align: right;
  margin-top: 7px;
  width: 100%;
}
.multiselectParentDiv {
  float: left;
  text-align: left;
  width: 100%;
  label {
    // color: white;
    margin-bottom: 0;
    font-size: 13px;
  }
}
div, label, button, small, span {
  font-weight: 504 !important;
}
.editIcon {
  margin-left: 8px;
  position: relative;
  top: -4px;
}

@media(max-width:360px) {
  .bill_buttons button{
    text-align: center;
    margin: auto;
    display: block;
    margin-bottom: 17px;
    width: 170px;
    float: unset !important;
  }
  .AddMoreDetail{
    text-align: center;
    padding: 0px !important;
  }
}
@media(max-width:400px) {
  .add_billing{
    float: left;
    margin-bottom: 10px;
    margin-right:10px;
  }
}
</style>
